export const PrivateTerm = {
  "Private Lesson": "Private Lesson",
  "Private Session": "Private Session",
  "Personal Training": "Personal Training",
  Tutoring: "Tutoring",
  "Private Training": "Private Training",
  "Private Instruction": "Private Instruction",
  "One-on-one Session": "One-on-one Session",
} as const;

export const EvaluationTerm = {
  Evaluation: "Evaluation",
  Assessment: "Assessment",
  Review: "Review",
} as const;

export const AdministratorTerm = {
  Administrator: "Administrator",
  Manager: "Manager",
  Owner: "Owner",
  Leader: "Leader",
  Supervisor: "Supervisor",
  Director: "Director",
  Principal: "Principal",
} as const;

export const StaffTerm = {
  Staff: "Staff",
  Employee: "Employee",
  Instructor: "Instructor",
  Trainer: "Trainer",
  Teacher: "Teacher",
  Coach: "Coach",
  Associate: "Associate",
  Volunteer: "Volunteer",
  Mentor: "Mentor",
  Aide: "Aide",
  Contractor: "Contractor",
} as const;

export const CustomerTerm = {
  Customer: "Customer",
  Member: "Member",
  Account: "Account",
  Client: "Client",
} as const;

export function getPastTerminology(term: string) {
  let result = term;

  if (term === EvaluationTerm.Assessment) {
    result = "Assessed";
  } else if (term === EvaluationTerm.Evaluation) {
    result = "Evaluated";
  } else if (term === EvaluationTerm.Review) {
    result = "Reviewed";
  }

  return result;
}

export function getVerbTerminology(term: string) {
  let result = term;

  if (term === EvaluationTerm.Assessment) {
    result = "Assess";
  } else if (term === EvaluationTerm.Evaluation) {
    result = "Evaluate";
  } else if (term === EvaluationTerm.Review) {
    result = "Review";
  }

  return result;
}

export function getPluralTerminology(term: string) {
  let result = `${term}s`;

  if (term.endsWith("s")) {
    result = `${term}es`;
  }

  if (term === "Coach") {
    result = "Coaches";
  } else if (term === "Staff") {
    result = "Staff";
  } else if (term === "Participants") {
    result = "Participants";
  }

  return result;
}

export const AgeUnit = {
  None: "",
  Years: "years",
  Months: "mo.",
} as const;

export const AgePrefix = {
  None: "",
  Age: "Age",
} as const;

export type AgeUnit = (typeof AgeUnit)[keyof typeof AgeUnit];
export type AgePrefix = (typeof AgePrefix)[keyof typeof AgePrefix];

interface GetUserAgePayload {
  ageNumber: number;
  ageUnit: AgeUnit;
  agePrefix: AgePrefix;
  ageString: string;
}

export default (
  birthDate: Date | string,
  compareDate?: Date
): GetUserAgePayload => {
  let ageNumber = 0;
  let ageUnit: AgeUnit = AgeUnit.None;
  let agePrefix: AgePrefix = AgePrefix.Age;
  let ageString = "";

  if (!birthDate) {
    return {
      ageNumber,
      ageUnit,
      agePrefix,
      ageString,
    };
  }

  const userBirthDate = new Date(birthDate);

  let today = new Date();

  if (compareDate) {
    today = new Date(compareDate);
  }

  const monthDiff = today.getTime() - userBirthDate.getTime();
  const diffDate = new Date(monthDiff);

  ageNumber = diffDate.getFullYear() - 1970;

  if (ageNumber < 0) {
    return {
      ageNumber: 0,
      ageUnit,
      agePrefix,
      ageString,
    };
  }

  ageUnit = AgeUnit.Years;
  ageString = `${agePrefix} ${ageNumber}`;

  const yearsDifference = today.getFullYear() - userBirthDate.getFullYear();
  const monthsDifference = today.getMonth() - userBirthDate.getMonth();
  let monthAge = yearsDifference * 12 + monthsDifference;

  if (today.getDate() < userBirthDate.getDate()) {
    monthAge -= 1;
  }

  if (monthAge < 24) {
    ageNumber = monthAge;
    ageUnit = AgeUnit.Months;
    agePrefix = AgePrefix.None;
    ageString = `${ageNumber} ${ageUnit}`;
  }

  return {
    ageNumber,
    ageUnit,
    agePrefix,
    ageString,
  };
};
